<template>
  <v-container
    fluid
    class="recurring-job-listing white-background main-listing-page pb-0"
  >
    <div>
      <RecurringListingTemplate
        :is-hideColumn="isHideColumn"
        visitType="all"
        :detail-route="`${
          follow_up_route == 1 ? 'follow-up.detail' : 'recurring-job.detail'
        }`"
      ></RecurringListingTemplate>
    </div>
  </v-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RecurringListingTemplate from "@/view/pages/recurring-job/Recurring-Listing-Template";
import { QUERY } from "@/core/services/store/request.module";

export default {
  name: "recurring-job",
  mounted() {
    const route_name = this.$route.name;
    this.follow_up_route = this.$route.query.follow_up;
    if (this.follow_up_route && this.follow_up_route == 1) {
      this.title = "Follow Up";
    } else {
      this.title = "Recurring Job";
    }
    const indexd = route_name.indexOf("admin");
    if (indexd >= 0) {
      this.isAdmin = true;
    }
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
    /*   this.getVisitCounts(); */
  },
  data() {
    return {
      title: null,
      isAdmin: false,
      isHideColumn: false,
      pageLoading: false,
      follow_up_route: null,
      // visitTab: null,
      count_all: 0,
      count_overdue: 0,
      count_today: 0,
      count_tomorrow: 0,
      count_this_week: 0,
      count_completed: 0,
      count_followup: 0,
      count_upcomming: 0,
      counts: {
        all: 0,
        overdue: 0,
        today: 0,
        tomorrow: 0,
        this_week: 0,
        upcomming: 0,
        completed: 0,
        follow_up: 0,
      },
    };
  },
  methods: {
    getVisitCounts() {
      const _this = this;

      _this.$store
        .dispatch(QUERY, {
          url: "visit/counts",
          data: {
            module_type: "visit",
          },
        })
        .then(({ data }) => {
          _this.count_all = data.count_all;
          _this.count_overdue = data.count_overdue;
          _this.count_today = data.count_today;
          _this.count_tomorrow = data.count_tomorrow;
          _this.count_this_week = data.count_this_week;
          _this.count_completed = data.count_completed;
          _this.count_followup = data.count_followup;
          _this.count_upcomming = data.count_upcomming;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  components: {
    RecurringListingTemplate,
  },
  created() {
    if (this.$route.query && this.$route.query.tab) {
      this.visitTab = this.$route.query.tab;
    }
  },

  computed: {
    visitTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.visitTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "all";
      },
    },
  },
};
</script>
